import React, { useCallback, useEffect, useState, useRef } from "react";

import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal,
    Spinner
} from "react-bootstrap";
import SimpleMDE from "react-simplemde-editor";

import ImageUploadingComponent from "../UploadImageComponent";
import Loading from "../../Loading";

const BaseTaskModal = ({handleClose, onSave, task = null, show, modalTitle, taskAdded, hideType = false}) => {
    const [users, setUsers] = useState(null);
    const [types, setTypes] = useState(null);
    const [loading, setLoading] = useState(false);
    const [assignedUserId, setAssignedUserId] = useState(null);
    const [typeId, setTypeId] = useState(null);
    const [requiredAmount, setRequiredAmount] = useState(null);
    const [deadlineDate, setDeadlineDate] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState(null);
    const imageUploadRef = useRef(null);
    const [requestParameters, setRequestParameters] = useState(null);

    useEffect(() => {
        if (!show) {
            return;
        }
        setAssignedUserId(task?.assignedUser?.id);
        setTypeId(task?.type?.type?.value);
        setDeadlineDate(task?.deadlineDate);
        setDescription(task?.description);
        setRequiredAmount(task?.requiredAmount);
        setRequestParameters({
            taskId: task?.id
        });
    }, [show, task, task?.assignedUser, task?.deadlineDate, task?.description, task?.imagesCount, task?.type?.type?.value]);

    const onShow = useCallback(async () => {
        setLoading(true);
        axios.get("/getUsers")
            .then((response) => {
                if(response.data.valid) {
                    let users = response.data.data.filter(user => {
                        return user.enabled && user.intranet;
                    });
                    users = users.sort((user1, user2) => {
                        if(user1.name < user2.name) return -1;
                        if(user1.name > user2.name) return 1;
                        return 0;
                    });
                    setUsers(users);
                } else {
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er ging iets fout. Probeer het later opnieuw.");
            })
            .finally(() => {
                setLoading(false);
            });

        axios.get("/getTaskTypes")
            .then((response) => {
                if(response.data.valid) {
                    setTypes(response.data.taskTypes);
                } else {
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er ging iets fout. Probeer het later opnieuw.");
            });
    }, []);

    const handleSave = useCallback(async () => {
        try {
            const savedTask = await onSave({
                assignedUserId,
                typeId,
                deadlineDate,
                description,
                requiredAmount,
            });
    
            if (savedTask) {
                const additionalRequestParameters = {
                    taskId: savedTask.id
                };
                await imageUploadRef.current?.uploadImages(additionalRequestParameters);
    
                const uploadError = imageUploadRef.current?.getError();
                if (uploadError) {
                    setError(uploadError);
                    return;
                }
    
                if (taskAdded) {
                    taskAdded(savedTask);
                }
                setAssignedUserId(null);
                setTypeId(null);
                setRequiredAmount(null);
                setDeadlineDate("");
                setDescription("");
            } else {
                setError("Taak opslaan mislukt.");
            }
        } catch (error) {
            setError("Er is een fout opgetreden:", error);
        }
    }, [onSave, assignedUserId, typeId, deadlineDate, description, requiredAmount, taskAdded]);

    return (
        <Modal
            size="xl"
            onHide={ handleClose }
            show={ show }
            onShow={ onShow }
        >
            <Modal.Header closeButton>
                <Modal.Title>{ modalTitle }</Modal.Title>
            </Modal.Header>
            <form className="mb-0">
                <Modal.Body>
                    { error && (
                        <Alert variant="danger">{ error }</Alert>
                    )}
                    { users !== null ? (
                        <React.Fragment>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group controlId="assignedUser">
                                        <Form.Label>Toegewezen werknemer</Form.Label>
                                        <Form.Control as="select" value={ assignedUserId } onChange={(e) => setAssignedUserId(e.target.value)}>
                                            <option value="">Selecteer een werknemer</option>
                                            {users.map(user => (
                                                <option key={user.id} value={user.id}>{user.name}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    { !hideType && (
                                        <Form.Group controlId="type">
                                            <Form.Label>Type</Form.Label>
                                            <Form.Control as="select" value={ typeId } onChange={(e) => setTypeId(e.target.value)}>
                                                <option value="">Selecteer een type</option>
                                                {types && types.map(type => (
                                                    <option key={type.id} value={type.value}>{type.name}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    )}
                                    { (typeId === "images" || typeId === "files") && !hideType && (
                                        <Form.Group controlId="requiredAmount">
                                            <Form.Label>Minimale hoeveelheid {typeId === "images" ? "foto's" : "bestanden"} </Form.Label>
                                            <Form.Control type="number" value={ requiredAmount } onChange={(e) => setRequiredAmount(e.target.value)} />
                                        </Form.Group>
                                    )}
                                    <Form.Group controlId="date">
                                        <Form.Label>Deadline datum</Form.Label>
                                        <Form.Control type="date" value={ deadlineDate } onChange={(e) => setDeadlineDate(e.target.value)}/>
                                    </Form.Group>
                                </div>
                                <div className="col">
                                    <p className="mb-2">Beschrijving</p>
                                    <SimpleMDE
                                        value={ description }
                                        onChange={(value) => setDescription(value)}
                                    />
                                </div>
                            </div>
                            <div className="col">
                                <ImageUploadingComponent
                                    allowImages
                                    allowFiles
                                    ref={imageUploadRef}
                                    isDescriptionImage
                                    requestParameters={requestParameters}
                                />
                            </div>
                        </React.Fragment>
                    ) : (
                        <Loading/>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleClose }>
                        Annuleer
                    </Button>
                    <Button variant="primary" onClick={ handleSave } disabled={ loading }>
                        { loading && (
                            <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                        )}
                        Opslaan
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default BaseTaskModal;
