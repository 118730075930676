import React, { useCallback, useState } from "react";

import axios from "axios";
import BaseTaskModal from "./BaseTaskModal";

const EditTaskModal = ({ task, show, handleClose, taskUpdated }) => {
    const [error, setError] = useState(null);

    const handleTaskUpdate = useCallback(
        async ({ assignedUserId, typeId, deadlineDate, description }) => {
            try {
                const response = await axios.post("/updateTask", {
                    id: task.id,
                    assignedUserId,
                    description,
                    type: typeId,
                    deadlineDate,
                });
                if (!response.data.valid) {
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                    return null;
                }
                const updatedTask = response.data.task;
                await taskUpdated(updatedTask);
                return updatedTask;

            } catch (requestError) {
                console.error(requestError);
                setError("Er ging iets fout. Probeer het later opnieuw.");
                return null;
            }
        },
        [task, taskUpdated]
    );


    return (
        <BaseTaskModal
            show={show}
            task={task}
            handleClose={handleClose}
            modalTitle="Taak bewerken"
            onSave={handleTaskUpdate}
            hideType={true}
            error={error}
        />
    );
}

export default EditTaskModal;
